import {Base64} from 'js-base64'
import Long from 'long'

window.Long = Long

export default class RFS {
    static RFS() {
        return {
            // String 转 byte[]
            stringToBytes(utf8string) {
                let back = []
                for (let i = 0; i < utf8string.length; i++) {
                    let code = utf8string.charCodeAt(i)
                    if (code >= 0x00 && code <= 0x7f) {
                        back.push(code)
                    } else if (code >= 0x80 && code <= 0x7ff) {
                        back.push((192 | (31 & (code >> 6))))
                        back.push((128 | (63 & code)))
                    } else if ((code >= 0x800 && code <= 0xd7ff) ||
                        (code >= 0xe000 && code <= 0xffff)) {
                        back.push((224 | (15 & (code >> 12))))
                        back.push((128 | (63 & (code >> 6))))
                        back.push((128 | (63 & code)))
                    }
                }
                for (let i = 0; i < back.length; i++) {
                    back[i] &= 0xff
                }
                return back
            },
            // byte[] 转 String
            byteToString(arr) {
                if (typeof arr === 'string') {
                    return arr
                }
                let str = ''
                let _arr = arr
                for (let i = 0; i < _arr.length; i++) {
                    let one = _arr[i].toString(2)
                    let v = one.match(/^1+?(?=0)/)
                    if (v && one.length == 8) {
                        let bytesLength = v[0].length
                        let store = _arr[i].toString(2).slice(7 - bytesLength)
                        for (let st = 1; st < bytesLength; st++) {
                            store += _arr[st + i].toString(2).slice(2)
                        }
                        str += String.fromCharCode(parseInt(store, 2))
                        i += bytesLength - 1
                    } else {
                        str += String.fromCharCode(_arr[i])
                    }
                }
                return str
            },
            // long 转 byte[]
            longToBytes(num) {
                let srcValue = Long.fromString(num + '')
                let result = srcValue.toBytes()
                for (let i = 0; i < result.length; i++) {
                    result[i] = this.byteNumber(result[i])
                }
                return result
            },
            // int 转 byte[]
            intToByte4(i) {
                let targets = []
                targets[0] = (i & 0xFF)
                targets[1] = (i >> 8 & 0xFF)
                targets[2] = (i >> 16 & 0xFF)
                targets[3] = (i >> 24 & 0xFF)
                return targets
            },
            // short 转 byte[]
            shortToByte(srcValue) {
                let result = []
                result[0] = (srcValue >> 8 & 0xFF)
                result[1] = (srcValue & 0xFF)
                for (let i = 0; i < result.length; i++) {
                    result[i] = this.byteNumber(result[i])
                }
                return result
            },
            // number 强转 byte
            byteNumber(lo) {
                let w = lo - 127
                if (w > 0) {
                    let j = (w - 129)
                    if (j > 128) {
                        let wd = parseInt(j / 128)
                        wd++
                        return (j - (wd * 128))
                    } else {
                        return j
                    }
                } else {
                    return lo
                }
            },
            // 校验码
            calculateXORValue(data) {
                let result = data[0]
                for (let i = 1; i < result.length - 2; i++) {
                    let item = data[i]
                    let rmp = result ^ item
                    result = this.byteNumber(rmp)
                }
                return result
            }
        }
    }

    /**
     * 虚拟设备生成码
     * @param {*} pid 产品PID
     * @param {*} callback 随机回调码
     */
    static virtualBinding(pid, callback) {
        let _json = {
            pid,
            pSecret: callback
        }
        let str = Base64.encode(JSON.stringify(_json))
        return '@' + str
    }

    /**
     * 预览设备生成码
     * @param {*} viewId 预览ID
     * @param {*} pid 产品PID
     * @param {*} callback 随机回调码
     */
    static previewBinding(viewId, pid, callback) {
        let _json = {
            did: viewId,
            pid,
            pSecret: callback
        }
        console.log(_json)
        let str = Base64.encode(JSON.stringify(_json))
        console.log('str', str)
        return '&' + str
    }
}
